import consumer from "./consumer"
consumer.subscriptions.create("OrderChannel", {
  connected() {
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if(data.flag == 'refresh_expeditor' || window.location.pathname.includes('/expeditor/')){
      if(location.href.includes("default_station_list")){
        location.reload();
      }
      if (window.location.pathname.includes('/expeditor/')){
        $.ajax({url: window.location.pathname,
          type: "GET", dataType: 'script'});
      }
      setLiquidDesign();
      // refresh masonry multiple times
      setTimeout(function(){ 
          if($('#isLiquidDesign').val() == 'yes'){
            $('#orderListing').masonry('layout');
          }
          setLiquidDesign();
        }  , 1000);
      setTimeout(function(){ 
          if($('#isLiquidDesign').val() == 'yes'){
            $('#orderListing').masonry('layout');
          }
          setLiquidDesign();
          $('.orderCountH2').html('Orders in Queue (' + $.unique($.map($('.OrderCard'), function(element, index){return $(element).data('id')})).length + ')');
        }, 2000);
      return true;
    }

    var currentLocationId = parseInt($('#current_location_id').val());
    console.log(data);
    if(data.location_id === currentLocationId){
      if(data.flag == 'refresh_page'){
        $('.refresh_kitchen').click();
      }

      if(data.flag == 'new_order'){
        var kitchen_station_id = $('#kitchen_station_id').length == 0 ? 0 : $('#kitchen_station_id').val();
        $.ajax({
          url: '/kds/' + data.order_id + '/render_new_order',
          type: "GET",
          data: {kitchen_station_id: kitchen_station_id},
          success: function(){
            if($('.order_card_' + data.order_id).length >= 1 && (!data.skip_sound)){
              $('#notifyOrder')[0].play();
            }
          }}); 
        if (location.href.includes("default_station_list")){
          location.reload();
        }     
      }

      if(data.flag == 'order_completed'){
        if($('.order_card_' + data.order_id).length >= 1){
          if($('#isLiquidDesign').val() == 'yes'){
            $('#orderListing').masonry('remove', $('.order_card_' + data.order_id));
          }
          else{
            $('.order_card_' + data.order_id).remove();
          }
        }
        if (location.href.includes("default_station_list")){
          location.reload();
        }     
      }

      if(data.flag == 'order_item_completed'){
        $('#item_' + data.order_item_id).prop('checked', data.is_item_completed);
        $('#item_' + data.order_item_id).data('quantity', data.quantity_for_update);
        $.each($('#order_items_' + data.order_item_id).find('.strikeItem'), function(index, element){
          if(data.is_item_completed){
            $(element).html('<strike>' +  $(element).html() + '<strike>');
          }
          else{
            $(element).html($(element).html().replace("<strike>", '').replace("</strike>", ''));
          }
        })
        if (location.href.includes("default_station_list")){
          location.reload();
        }
      }
      
      if(data.flag == 'payment_status'){
        $('.orderPayment_' + data.order_id).html(data.payment_status);
      }

      $('.orderCountH2').html('Orders in Queue (' + $.unique($.map($('.OrderCard'), function(element, index){return $(element).data('id')})).length + ')');
      setLiquidDesign();
      // refresh masonry multiple times
      setTimeout(function(){ 
          if($('#isLiquidDesign').val() == 'yes'){
            $('#orderListing').masonry('reloadItems');
            $('#orderListing').masonry('layout');
          }
          setLiquidDesign();
        }  , 1000);
      setTimeout(function(){ 
          if($('#isLiquidDesign').val() == 'yes'){
            $('#orderListing').masonry('reloadItems');
            $('#orderListing').masonry('layout');
          }
          setLiquidDesign();
          $('.orderCountH2').html('Orders in Queue (' + $.unique($.map($('.OrderCard'), function(element, index){return $(element).data('id')})).length + ')');
        }, 2000);
    }
  }
});
