window.alphabetically_order = function(){
  Array.from(reorder_modifier.getElementsByTagName("LI"))
    .sort((a, b) => a.textContent.localeCompare(b.textContent))
    .forEach(li => reorder_modifier.appendChild(li));
  updateIndex();
}

window.updateIndex = function(){
  reorder_ids = [];
  $.each($('.manage-ids'), function(ind, elem){
    reorder_ids.push($(elem).attr('data-id'));
  });
  $('#reorder_ids').val(reorder_ids);
}

window.reorder_table_rows = function(reorder_table){
  Sortable.create(reorder_table, {
    handle: '.handle',
    sort: true,
    onUpdate: function(){
      updateIndex();
    }
  });
}

// Created method for sorting tips
window.reorder_rows_alphabetically = function(){
  // To traverse all hidden elements to the last which we have in out table
  $('.reorder-area > input:hidden').each(function(){
    $(this).insertAfter($("table tr:last"));
  })
  // For sorting rows in ascending order
  for(i = 0; i < $('.sorting_td input:text').length; i++){
    previous_td_value = ""
    $('.sorting_td input:text').each(function(){
      if(previous_td_value == ""){
        previous_td_value = $(this).val().toUpperCase();
      }else{
        if(previous_td_value > $(this).val().toUpperCase()){
          var row = $(this).parents("tr:first");
          row.insertBefore(row.prev('.category-column'));
        }else{
          previous_td_value = $(this).val().toUpperCase()
        }
      }
    })
  }
  updateIndex();
}

changeDropIcon = function(element){
  if($(element).hasClass("fa-caret-down")){
      $(element).removeClass("fa-caret-down")
      $(element).addClass("fa-caret-up")
    }else{
      $(element).addClass("fa-caret-down")
      $(element).removeClass("fa-caret-up")
    }
}