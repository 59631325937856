require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('bootstrap')
require("packs/app.min.js")
require("packs/bootstrap-datetimepicker.min")
require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")
require('jquery.blockUI.min.js')
require("jquery-mask-plugin")
require("chartkick")
require("chart.js")
require("parsleyjs")
require("popper.js")
// require("owl.carousel.js")
require("timepicker")
import Sortable from "sortablejs";
window.Sortable = Sortable;
require('drag_and_sort.js')
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "jquery.min.js"
import "bootstrap.min.js"
import "jquery.multiselect.js"
import 'select2'; // globally assign select2 fn to $ element
import 'select2/dist/css/select2.css';
import 'custom';
import 'timepicker/jquery.timepicker.css';
import 'cocoon';
import 'bootstrap-datepicker';
import 'sortablejs';
import 'sidebar';
import "@fortawesome/fontawesome-free/css/all.css";
import "multiplecheckbox";
import 'chartjs-plugin-datalabels'
import "file_name";
window.jQuery = $;
window.$ = $;
$.jMaskGlobals.watchDataMask = true;
import 'owl.carousel/dist/assets/owl.carousel.css';
let owl_carousel = require('owl.carousel');
window.fn = owl_carousel;

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

import "controllers"
Chart.defaults.global.plugins.datalabels.display = false

