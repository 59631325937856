var script = document.createElement('script');
script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB_n-RDApslpQKgNVA6XkWSVqGcW9KAbJg&libraries=places';
function initAutocomplete(id){
  var places = new google.maps.places.Autocomplete(document.getElementById(id));
  google.maps.event.addListener(places, 'place_changed', function() {
    var place = places.getPlace();
    var address = place.formatted_address;
    if(address){
      var  address_value = address.split(",");
      count = address_value.length;
      country = address_value[count-1];
      state = address_value[count-2];
      city = address_value[count-3];
      var street = "";
      var j=0;
      if (count > 4) {
        while (j <= (count-4)) {
          street += address_value[j];
          j++;
        }
      }
      else{
        street = address_value[count-4];
      }
      var z = state.split(" ");
      var i =z.length;
       state  = z[1]
      if(i>2){
        zipcode  = z[2]
      }else{
        zipcode = ""
      }
      var formattedAddress = address.replace(state, '').replace(country, '').replace(zipcode, '').replace(city, '').split(', ')
      var OnlyAddress = []
      $.each(formattedAddress, function(index, element){
        if(element.replace(' ', '').replace(' ', '').replace(' ', '').replace(',', '').replace(',', '').length != 0){
          OnlyAddress.push(element)
        }
      })

      $('.state').val(state)
      $('.country').val(country);
      $('.add_address').val(street);
      $('.zip_code').val(zipcode);
      $('.city').val(city)
      // $('.atitude').val(place.geometry.location.lat())
      $('.latitude').val(place.geometry.location.lat())
      $('.longitude').val(place.geometry.location.lng())
      // changes to store address lines
      var formattedAddress = address.replace(state, '').replace(country, '').replace(zipcode, '').replace(city, '').split(', ')
      var OnlyAddress = []
      $.each(formattedAddress, function(index, element){
        if(element.replace(' ', '').replace(' ', '').replace(' ', '').replace(',', '').replace(',', '').length != 0){
          OnlyAddress.push(element)
        }
      })

      $('.addressLine1').val(OnlyAddress[0]);
      // if(OnlyAddress[1] === undefined){
      //   $('.addressLine2').val(city);
      // } else {
        $('.addressLine2').val(OnlyAddress[1]);
      // }
      // if(OnlyAddress[2] === undefined && OnlyAddress[1] !== undefined){
      //   $('.addressLine3').val(city);
      // } else {
        $('.addressLine3').val(OnlyAddress[2]);
      // }
      // end changes to store address lines
      
    }else{
      alert("Please Enter valid Address");
    }
  });
}
$(document).on('turbolinks:load', function(){
  $("a[data-range=today]").addClass("link-active");
  if ($('.add_address').length > 0){
    initAutocomplete($('.add_address').attr('id'))
  }
  $('#start_date').datetimepicker({
    icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "fa fa-chevron-left",
        next: "fa fa-chevron-right",
        today: "fa fa-clock-o",
        clear: "fa fa-trash-o"
    }
  });
  $('#end_date').datetimepicker({icons: {
     
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "fa fa-chevron-left",
        next: "fa fa-chevron-right",
        today: "fa fa-clock-o",
        clear: "fa fa-trash-o"
      }
    });
});

// $(document).on('click', '.add_fields', function(){
//   // if ($('.add_address').length > 0){
//     initAutocomplete($('.add_address').attr('id'))
//   // }
// });

$(document).on('change', '#tax_tax_type', function(e){
  if($(this).val() == "$"){
    $(this).parents().next().next("div.flate_rate").show();
    $(this).parents().next("div.tax_rate").hide();
  } else if($(this).val() == "%") {
    $(this).parents().next("div.tax_rate").show();
    $(this).parents().next().next("div.flate_rate").hide();
  }
});

$(document).on('keyup keypress', '.phone-number', function(e){
  phone_number = /^(1\s?)?((\([0-9]{3}\)-)|[0-9]{3})[\s\-]?[\0-9]{3}-[\s\-]?[0-9]{4}$/
  space_regex = /\(?\d{3}\)?[ ]\d{3}?[ ]\d{4}/
  dash_regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
  var curchr = this.value.length;
  var curval = $(this).val();

  // if (($("#phone_number-error").attr('style') == "display: block;") || ($("#phone_number-error").attr('style') == "display: block;")){
  // }else{
  //   if ((curval != "") && space_regex.test(curval)){
  //     hide_error_msg()
  //   }else if(dash_regex.test(curval)){
  //     hide_error_msg()
  //   }else if(phone_number.test(curval)){
  //     hide_error_msg()
  //   }else{
  //     display_error_msg()
  //   }

  // }
  // if (curval == ""){
  //   hide_error_msg()
  // }
  if (curchr == 3 && curval.indexOf("(") <= -1) {
    if (e.which != 8){
      $(this).val("(" + curval + ")" + " ");
    }
  } else if (curchr == 4 && curval.indexOf("(") > -1) {
    if (e.which != 8){
      $(this).val(curval + ")-");
    }
  } else if (curchr == 5 && curval.indexOf(")") > -1) {
    if (e.which != 8){
      $(this).val(curval + "-");
    }
  } else if (curchr == 9) {
    if (e.which != 8){
      $(this).val(curval + "-");
    }
    $(this).attr('maxlength', '14');
  }
});
$(document).on('click', '.hide_inventory', function(){
  Id  = $(this).attr('id')
  is_checked = $(this).data('hide')
  url = "/inventories/"+Id+"/hide"
  $.ajax({
    url: url,
    type: "PUT",
    data: {"is_hide" : is_checked},
    dataType: "JSON",
    success: function (data) { 
      $(".hidden_"+data.id).hide()
    }
  })
})
$(document).on('click', '.enable_inventory', function(){
  Id  = $(this).attr('id').split("enable_")[1]
  is_checked = $(this).is(":checked")
  url = "/inventories/"+Id+"/enable_for_olobuddy"
  $.ajax({
    url: url,
    type: "PUT",
    data: {"enable" : is_checked},
    dataType: "JSON",
  })
})
$(document).on('click', '.hide_employee', function(){
  Id  = $(this).attr('id')
  is_checked = $(this).data('hide')
  url = "/employees/"+Id+"/hide"
  $.ajax({
    url: url,
    type: "PUT",
    data: {"is_active" : is_checked},
    dataType: "JSON",
    success: function (data) { 
      $(".hidden_"+data.id).hide()
    }
  })
})
$(document).on('click', '.user_employee', function(){
  Id  = parseInt($(this).attr('id'))
  is_checked = $(this).prop('checked')
  url = "/employees/"+Id+"/update_user"
  $.ajax({
    url: url,
    type: "PUT",
    data: {"is_user" : is_checked},
    dataType: "script",
    success: function (data) { 
      // $(".hidden_"+data.id).hide()
    }
  })
})
$(document).on('click', '.hide_modifier', function(){
  Id  = $(this).attr('id')
  is_checked = $(this).data('hide')
  url = "/modifier_groups/"+Id+"/hide"
  $.ajax({
    url: url,
    type: "PUT",
    data: {"is_hide" : is_checked},
    dataType: "JSON",
    success: function (data) { 
      $(".hidden_"+data.id).hide()
    }
  })
})
$(document).on('click', '.hide_category', function(){
  Id  = $(this).attr('id')
  is_checked = $(this).data('hide')
  url = "/categories/"+Id+"/hide"
  $.ajax({
    url: url,
    type: "PUT",
    data: {"is_hide" : is_checked},
    dataType: "JSON",
    success: function (data) {
      $(".hidden_"+data.id).hide()
    }
  })
})
$(document).on('click', '.enable_category', function(){
  Id  = $(this).attr('id').split("enable_")[1]
  is_checked = $(this).is(":checked")
  url = "/categories/"+Id+"/enable_for_olobuddy"
  $.ajax({
    url: url,
    type: "PUT",
    data: {"enable" : is_checked},
    dataType: "JSON"
  })
})
$(document).on('cocoon:after-insert', '#modifier-btn', function(e, insertedItem, originalEvent) {
  var id = parseInt($('#ModifierId').val()) + parseInt(1);
  insertedItem.find(".item-list-wrap").attr('id', e.timeStamp)
  insertedItem.find('input.sorting-value').val(id)
  insertedItem.attr('data-id', id);
  $('#ModifierId').val(id);
  insertedItem.find('input.first_name').val($(".dummy-first-name").val())
  insertedItem.find('select.amount_sign').val($(".dummy-amount-sign").val())
  insertedItem.find('input.alt_name').val($(".dummy-alt-name").val())
  insertedItem.find('select.price_type').val($(".dummy-price-type").val())
  insertedItem.find('input.price').val($(".dummy-price").val())
  insertedItem.find('select.min_required').val($(".dummy-min-required").val())
  insertedItem.find('select.max_allowed').val($(".dummy-max-required").val())
  let color_array = ["#696969", "#3ECBAF", "#2FA1FF", "#FF8E36", "#FD5F9A", "#A262E9", "#FF5668", "#40D6E3"]
  selected_color = color_array[Math.floor(Math.random() * color_array.length)]
  insertedItem.find('input.color_code').val(selected_color)
})
$(document).on('click', ".modifier-add-btn", function () {
  $(".add_fields").click();
  clearVal();
  $(".modifier-add-btn").addClass('disable-btn');
});
$(document).on('change', ".modifier-group-list", function(e){
  e.stopImmediatePropagation()
  $.ajax({
    url: '/level_addons/render_new_levels',
    type: "GET",
    data: {level_id: $(this).attr('data-level-id'), 
    modifier_group_id: $('#modifier_group').val(), 
    inventory_id: $('#inventory').val(),
    current_index: $('.remove_fields').length
    },
    dataType: "script"
  })
})

$(document).on('change', ".inventory-list", function(event){
  event.stopImmediatePropagation()
  $.ajax({
    url: '/level_addons/render_new_levels',
    type: "GET",
    data: {level_id: $(this).attr('data-level-id'), 
    modifier_group_id: $('#modifier_group').val(), 
    inventory_id: $('#inventory').val(),
    current_index: $('.remove_fields').length
    },
    dataType: "script"
  })
})

$(document).on('change', ".inventory, .modifier-group", function(event){
  event.stopImmediatePropagation();
  var element = $(this).closest(".applicable_cat_inv")
  element.find(".applicable_id").val($(this).val())
})

function clearVal(){
  $(".dummy-first-name").val("")
  $(".dummy-amount-sign").val("")
  $(".dummy-alt-name").val("")
  $(".dummy-price-type").val("")
  $(".dummy-price").val("")
  $(".dummy-max-required").val("")
  $(".dummy-min-required").val("")
  $(".dummy-color").val("")
  $(".dummy-avatar").val("")
}

$(document).on('click', ".tip_add_button", function(){
  $(".tip_add_button").addClass('disable-btn')
})

$(document).on('keydown keyup focusout', '.add-field', function(){
  if ($(".dummy-first-name").val() != ""){
    $(".modifier-add-btn").removeClass('disable-btn')
  }else{
    $(".modifier-add-btn").addClass('disable-btn')
  }

  if (($(".tip_name").val() != "") && ($(".tip_rate").val() != "" )){
    $(".tip_add_button").removeClass('disable-btn')
  }else{
    $(".tip_add_button").addClass('disable-btn')
  }

  if (($(".tax-name").val() != "") && ($(".tax-rate").val() != "" ) && ($(".tax-type").val() != "" )){
    $(".tip_add_button").removeClass('disable-btn')
  }else{
    $(".tip_add_button").addClass('disable-btn')
  }

})
$(document).on('click', 'li.selection', function(){
  select_class = $(this).data('class')
  dropdown_class = select_box_class(select_class)
  // setTimeout(function(){
  //   $("."+select_class.split(' ')[0]+":checked").each(function( index ) {
  //     if ($(this).val() > 0){
  //       $("#"+dropdown_class+"_"+$(this).val()).remove()
  //       setId = dropdown_class+"_"+$(this).val()
  //       static_html = '<div class="item-list d-flex align-items-center justify-content-between '+setId+'" id="'+setId+'"><p>'+$(this).parent().next().text()+'</p><div class="action-area pl-2"><a href="javascript:;" class="remove" data-class="'+select_class+'><img src="https://gonaps.s3-us-west-1.amazonaws.com/uploads/mail-image/ic_delete.svg" alt="" /></a></div></div>'
  //       $("."+dropdown_class).append(static_html)
  //     }
  //   })
  //   $("."+select_class.split(' ')[0]+":not(:checked)").each(function( index ) {
  //     if ($(this).val() > 0){
  //       $("#"+select_class+"_"+$(this).data('value')).remove()
  //       $("."+select_class+"_"+$(this).data('value')).remove()
  //     }
  //   })
  // }, 2);
  var inv_ids = [];
  if ($(this).next().text() != "Select All"){
    if ($(this).hasClass("selected")){

      title = $(this).data('name')
      setId = select_class+"_"+$(this).data('value')
      price = $(this).data('price')
      price = parseFloat(price).toFixed(2);
      setId = select_class+"_"+$(this).data('value');
      static_html = "";
      if(location.href.includes("categories")) {
        inv_ids.push($('#Inventory_').val());
        inv_ids.push($(this).data('value'));
        $('#Inventory_').val(inv_ids);
        static_html = '<li class="category-column" id="'+setId+'"><div class="category-box d-flex align-items-center"><div class="category-box-info d-flex align-items-start"><img src="https://goipos.com/ic_move.svg" alt="" class="handle" /><div class="w-100 pl-2"><h5 class="text-gray2">'+title+'</h5><p class="text-blue1 fw-500">$'+price+'</p></div></div><div class="action-area"><a href="javascript:;" class="remove" id="'+setId+'" data-id="'+$(this).data('value')+'"><img src="https://gonaps.s3-us-west-1.amazonaws.com/uploads/mail-image/ic_delete.svg" alt="" /></a></div></div></li>'
      } else if(select_class == "ModifierGroup") {
        inv_ids.push($('#ModifierGroup_').val());
        inv_ids.push($(this).data('value'));
        $('#ModifierGroup_').val(inv_ids);
        static_html = '<div class="item-list d-flex align-items-center justify-content-between '+setId+'" id="'+setId+'"><img src="https://goipos.com/ic_move.svg" alt="" class="handle" /><p>'+title+'</p><div class="action-area pl-2"><a href="javascript:;" class="remove" id="'+setId+'" data-id="'+$(this).data('value')+'"><img src="https://gonaps.s3-us-west-1.amazonaws.com/uploads/mail-image/ic_delete.svg" alt="" /></a></div></div>'
      } else if(location.href.includes("category_groups")) {
        static_html = '<li class="category-column" id="'+setId+'"><div class="category-box d-flex align-items-center"><div class="category-box-info d-flex align-items-start"><div class="w-100 pl-2"><h5 class="text-gray2">'+title+'</h5></div></div><div class="action-area"><a href="javascript:;" class="remove" id="'+setId+'" data-id="'+$(this).data('value')+'"><img src="https://gonaps.s3-us-west-1.amazonaws.com/uploads/mail-image/ic_delete.svg" alt="" /></a></div></div></li>'
      } else if(location.href.includes("category_sections")) {
        static_html = '<li class="category-column" id="'+setId+'"><div class="category-box d-flex align-items-center"><div class="category-box-info d-flex align-items-start"><div class="w-100 pl-2"><h5 class="text-gray2">'+title+'</h5></div></div><div class="action-area"><a href="javascript:;" class="remove" id="'+setId+'" data-id="'+$(this).data('value')+'"><img src="https://gonaps.s3-us-west-1.amazonaws.com/uploads/mail-image/ic_delete.svg" alt="" /></a></div></div></li>'
      } else if(select_class == "Inventory") {
        
        if ($('.item-list').length > 0) {
          $("#imarketing_inventory_ids").val([])
          $("#imarketing_inventory_ids").val($(this).data('value'))
          $('.item-list').remove()

        }
        static_html = '<div class="item-list d-flex align-items-center justify-content-between '+setId+'" id="'+setId+'"><p>'+title+'</p><div class="action-area pl-2"><a href="javascript:;" class="remove" id="'+setId+'" data-id="'+$(this).data('value')+'"><img src="https://gonaps.s3-us-west-1.amazonaws.com/uploads/mail-image/ic_delete.svg" alt="" /></a></div></div>'
      }else if(select_class == "Ingredient"){
        static_html = '<div class="item-list d-flex align-items-center justify-content-between '+setId+'" id="'+setId+'"><p class="mr-2">'+title+'</p><input type="text" id="qty" name="inventory[ingredient_qty][]" class="ingredient_qty form-control col-lg-3 mr-auto", placeholder="qty" required="true"><div class="action-area pl-2"><a href="javascript:;" class="remove" id="'+setId+'" data-id="'+$(this).data('value')+'"><img src="https://gonaps.s3-us-west-1.amazonaws.com/uploads/mail-image/ic_delete.svg" alt="" /></a></div></div>'
      }
      else{
        static_html = '<div class="item-list d-flex align-items-center justify-content-between '+setId+'" id="'+setId+'"><p>'+title+'</p><div class="action-area pl-2"><a href="javascript:;" class="remove" id="'+setId+'" data-id="'+$(this).data('value')+'"><img src="https://gonaps.s3-us-west-1.amazonaws.com/uploads/mail-image/ic_delete.svg" alt="" /></a></div></div>'
      }
      ing_id = $(this).closest('.search-icon').children('.Ingredient').attr('id')
      if (ing_id != undefined){
        $(".item-list-wrap."+select_class+"#"+ing_id).append(static_html)
      }else{
        $(".item-list-wrap."+select_class).append(static_html)
      }
    }else{
      cbx_class = select_class+"_"+$(this).data('value')
      $("#"+cbx_class).remove()
      $(".item-list."+cbx_class).remove()
    }
  }
})
$(document).on('click', '.remove', function(){
  id = $(this).attr('id')
  console.log("xxxxxxxxxxx",id)
  // unchecked_checkbox(id)
  $("#"+id).remove();
  $("input."+id).click()
  $("."+id).prop('checked', false)
  $("."+id).removeClass('selected')
  updateIndex();
})

function unchecked_checkbox(id){
  current_id = id.replace("#","").split("_")
  name = current_id[0]
  newId = current_id[1]
  if (location.href.includes("categories")){
    newClass = "category_ids"
  }else if (name == "Inventory"){
    newClass = "modifier_group_inventory_ids"
  }else if (name == "ModifierGroup"){
    newClass = "inventory_modifier_group_ids"
  }else{
    newClass = name.toLowerCase()+"_ids"
  }
  $("."+newClass).each(function () {
    if ($(this).val() == newId){
      $(this).prop('checked', false)
      $("#"+newClass+" option[value="+$(this).val()+"]").removeAttr('selected')
    }
  })
}

function select_box_class(select_class){
  if (location.href.includes('categories')){
    dropdown_class = "Inventory"
  }else if (select_class.includes("category_ids")){
    dropdown_class = "Category"
  }else if(select_class.includes("inventory_tax_ids")){
    dropdown_class ="Tax"
  }else if(select_class.includes("modifier_group_inventory_ids")){
    dropdown_class = "Inventory"
  }else if(select_class.includes("Terminal")){
    dropdown_class = "Terminal"
  }else{
    dropdown_class = "ModifierGroup"
  }
  return dropdown_class
}
$(document).on('keyup', '.form-control', function(){
  var field = $(this);
  var $field = $(field) || field;
  /**
   * First wrap the previously occurring label in wrapper div with class 'field_with_errors'
   * Then Wrap the field within a wrapper div of same class
   * Then add validation message after the field with class name 'message'
   */
  if ($field.siblings('.message').length > 0) {
    $field.parent().prev('.field_with_errors').find('label').unwrap();
    $field.unwrap();
    $field.next('.message').remove();
    return true;
  }
  return false;
})
$(document).on('click', '.btn-modal', function(){
  if($(this).hasClass("done")) date_filter_report();
  $("#date_filter").val("Custom Date Range ");
})
// $(document).on('click', '.done', function(){
//   date_filter_report()
// })
// $(document).on('click', '.order-filter', function(){
//   if ($("option:selected", $(this)).text() == "Custom Date Range"){
//     $("#customedateFilter").modal('show');
//   }
// })
$(document).on('change', '.order-filter', function(){
    console.log($("option:selected", $(this)))
    if ($("option:selected", $(this)).text() == "Custom Date Range"){
      $("#customedateFilter").modal('show');
    }else{
      date_filter_report()
  }
})
$(document).on('click', '.order-next', function(){
    browser_url = $(location).attr("href")
    if (browser_url.includes("sales_overview")){
      req_url = "/reports/sales_overview"
    }else if (browser_url.includes("discounts")){
      req_url = "/reports/discounts"
    }else{
      req_url = "/reports/sales_report"
    }
    $.ajax({
      url: req_url,
      type: "GET",
      data: {date_filter: $('#date_filter').val(), terminal_id: $("#terminal").val(), employee_id: $("#employee").val(), order_type_id: $("#order_type").val(), next_start_date: $('#next_start_date').val(), prev_end_date: $('#prev_end_date').val(), next: true},
      dataType: "script"
    });
  });
  $(document).on('click', '.order-prev', function(){
    browser_url = $(location).attr("href")
    if (browser_url.includes("sales_overview")){
      req_url = "/reports/sales_overview"
    }else if (browser_url.includes("discounts")){
      req_url = "/reports/discounts"
    }else{
      req_url = "/reports/sales_report"
    }
    $.ajax({
      url: req_url,
      type: "GET",
      data: {date_filter: $('#date_filter').val(), terminal_id: $("#terminal").val(), employee_id: $("#employee").val(), order_type_id: $("#order_type").val(), next_start_date: $('#next_start_date').val(), prev_end_date: $('#prev_end_date').val(), next: false},
      dataType: "script"
    });
  });
function date_filter_report(){
  browser_url = $(location).attr("href")
    console.log(browser_url)
    if (browser_url.includes("refund")){
      req_url = "/reports/sales_overview_refund"
    }else if(browser_url.includes("sales_overview")){
      req_url = "/reports/sales_overview"
    }else if (browser_url.includes("discounts")){
      req_url = "/reports/discounts"
    }else{
      req_url = "/reports/sales_report"
    }
  let date_filter_value = ($('#date_filter').val() == "Custom Date Range ") ? "Custom Date Range" : $('#date_filter').val();
  $.ajax({
    url: req_url,
    type: "GET",
    data: {date_filter: date_filter_value, terminal_id: $("#terminal").val(), employee_id: $("#employee").val(), order_type_id: $("#order_type").val(), start_date: $("#start_date").val(), end_date: $("#end_date").val()},
    dataType: "script",
    success: function (data) { 
      console.log("sales overview report updated....!")
      $("#customedateFilter").modal('hide');
    }
  })
}
$(document).on('change', '.report-check', function(){
  CurrentId = "."+$(this).attr('id')
  if ($(this).is(':checked')){
    $(CurrentId).show()
    $(CurrentId).removeClass('hide')
  }else{
    $(CurrentId).hide()
    $(CurrentId).addClass('hide')
  }
})
$(document).on('click', '.sales_overview_filter', function(){
  newId = "#"+$(this).attr('id')
  if($(this).find('i.fa-check').hasClass('hide')){
    $(this).find('i.fa-check').removeClass('hide')
    $(newId).parents().parent().show()
  }else{
    $(newId).prop("selectedIndex", 0).click();
    $(newId).parent().parent().hide()
    $(this).find('i.fa-check').addClass('hide')
  }

})
// For inventory image

 
  $(document).on('click', '#all_printers',function(){
    var cbxs = $('td.printer_check input[type="checkbox"]');
    check_value = $(this).is(":checked");
    cbxs.prop("checked", check_value);
  });
  $(document).on('click', 'td.printer_check input[type="checkbox"]', function(){
    checkbox_length = $('td.printer_check input[type="checkbox"]').length;
    checked_checkbox = $('td.printer_check input[type="checkbox"]:checked').length;
    if (checkbox_length == checked_checkbox){
      $('#all_printers').prop("checked", true);
    }else{
      $('#all_printers').prop("checked", false);  
    }
  })


  $(document).on('click', '.delete_printer',function(){
    var checkbox_val = []
    $('td.printer_check input[type="checkbox"]:checked').each(function () {
      checkbox_val.push($(this).data('id'))
    });
    console.log(checkbox_val)
    if (checkbox_val.length == 0){
      alert("Please select atleast one printer.")
    }else{
      var val = confirm("Are you sure you want to delete selected Printers?")
    }
    if((checkbox_val.length > 0) && val){
      $.blockUI({message: '<img src=' + $('#loader_image').attr('src') + ' height=70px height=70px>', css: {'background-color': 'transparent', 'border': 'none'}});
      $.ajax({
        type: "GET",
        url: '/printers',
        data:{ printer: checkbox_val},
        dataType: 'script',
        success:function(data){
          $.unblockUI();
          $("#all_printers").prop('checked', false)
          console.log("Printers deleted successfully..");
        }
      });
    }
  })
  $(document).on('change', '.inventory-list, .modifier-group-list', function(){
    if(($('.inventory-list').val() == "") && ($('.modifier-group-list').val() == "")){
      $(".add-addon-level").addClass('disable-btn')
    }else{
      $(".add-addon-level").removeClass('disable-btn')
    }
  })
  $(document).on('cocoon:after-insert', '#modifier-btn', function(e, insertedItem, originalEvent) {
    var id = parseInt($('#ModifierId').val()) + parseInt(1);
    insertedItem.find(".item-list-wrap.Ingredient").attr('id', e.timeStamp)
    insertedItem.find('input.sorting-value').val(id)
    insertedItem.attr('data-id', id);
    $('#ModifierId').val(id);
    insertedItem.find('input.first_name').val($(".dummy-first-name").val())
    insertedItem.find('select.amount_sign').val($(".dummy-amount-sign").val())
    insertedItem.find('input.alt_name').val($(".dummy-alt-name").val())
    insertedItem.find('select.price_type').val($(".dummy-price-type").val())
    insertedItem.find('input.price').val($(".dummy-price").val())
    insertedItem.find('select.min_required').val($(".dummy-min-required").val())
    insertedItem.find('select.max_allowed').val($(".dummy-max-required").val())
    let color_array = ["#696969", "#3ECBAF", "#2FA1FF", "#FF8E36", "#FD5F9A", "#A262E9", "#FF5668", "#40D6E3"]
    selected_color = color_array[Math.floor(Math.random() * color_array.length)]
    insertedItem.find('input.color_code').val(selected_color)
    ingredient_select_id = insertedItem.find('.ingredient-ids').attr('id')
    $("#"+ingredient_select_id).multiselect({
      columns: 1,
      placeholder: 'Select Items',
      search: true,
      searchOptions: {
        'default': 'Search Items'
      },
      selectAll: false
    });
  })
  $(document).on('cocoon:after-remove', function(e, insertedItem, originalEvent) {
    insertedItem.find('.addongroup').detach();
  })

  $(document).on('click', '.refresh_kitchen', function(){
    location.reload();
  })

  $(document).on('click', '#full_screen_link', function(){
    toggleFullScreenLinkClick(document.getElementById('fullScreen'));
  });

  function toggleFullScreenLinkClick(elem) {
    if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
      if (elem.requestFullScreen) {
          elem.requestFullScreen();
      } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
          document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
      }
    }
  }

  setupPercentageField = function(){
    $(document).on('focusin', '.percentageField', function(){
      $(this).val($(this).val().replace('%', '').replace(' ', ''));
    });

    $(document).on('focusout', '.percentageField', function(){
      if($(this).val() != ''){
        $(this).val($(this).val().replace('%', '') + ' %');
      }
    });

    $(document).on('keypress', '.percentageField', function(event){
      var key = event.keyCode;
      return (key >= 48 && key <= 57) || ((!$(this).val().includes('.')) && key == 46)
    });
  }
  $(document).on('change', '#all_categories', function(){
    if ($(this).is(":checked")){
      $('.category').prop('checked', true)
    }else{
      $('.category').prop('checked', false)
    }
  })

  $(document).on('change', '#all_terminals', function(){
    if ($(this).is(":checked")){
      $('.terminal').prop('checked', true)
    }else{
      $('.terminal').prop('checked', false)
    }
  })

  $(document).on('change', '.category', function(){
    console.log("call")
    checked_count = $('input.category:checkbox:checked').length;
    unchecked_count = $('input.category:checkbox:not(":checked")').length
    if (unchecked_count == 0){
      $('#all_categories').prop('checked', true)
    }else{
      $('#all_categories').prop('checked', false)
    }
  })
  $(document).on('change', '.terminal', function(){
    console.log("call")
    checked_count = $('input.terminal:checkbox:checked').length;
    unchecked_count = $('input.terminal:checkbox:not(":checked")').length
    if (unchecked_count == 0){
      $('#all_terminals').prop('checked', true)
    }else{
      $('#all_terminals').prop('checked', false)
    }
  })

  $(document).on('turbolinks:load', function(){
    checked_count = $('input.category:checkbox:checked').length;
    unchecked_count = $('input.category:checkbox:not(":checked")').length
    if (unchecked_count == 0){
      $('#all_categories').prop('checked', true)
    }else{
      $('#all_categories').prop('checked', false)
    }
  })

  $(document).on('turbolinks:load', function(){
    checked_count = $('input.terminal:checkbox:checked').length;
    unchecked_count = $('input.terminal:checkbox:not(":checked")').length
    if (unchecked_count == 0){
      $('#all_terminals').prop('checked', true)
    }else{
      $('#all_terminals').prop('checked', false)
    }
  })
