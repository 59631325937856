// $(document).on('turbolinks:load', function(){
//   $("#category_ids").CreateMultiCheckBox({ width: '100%', defaultText : 'Select Below', height:'200px' });
//   $("#inventory_modifier_group_ids").CreateMultiCheckBox({ width: '100%', defaultText : 'Select Below', height:'200px' });
//   $("#inventory_tax_ids").CreateMultiCheckBox({ width: '100%', defaultText : 'Select Below', height:'200px' });
//   $("#category_inventory_ids").CreateMultiCheckBox({ width: '100%', defaultText : 'Select Below', height:'200px' });
//   $("#modifier_group_inventory_ids").CreateMultiCheckBox({ width: '100%', defaultText : 'Select Below', height:'200px' });
// });


// $(document).on('turbolinks:load', function(){
//   $(document).on("click", ".MultiCheckBox", function () {
//     var detail = $(this).next();
//     detail.show();
//     detail.UpdateCheck()
//   });

//   $(document).on("click", ".MultiCheckBoxDetailHeader input", function (e) {
//     e.stopPropagation();
//     var hc = $(this).prop("checked");
//     $(this).closest(".MultiCheckBoxDetail").find(".MultiCheckBoxDetailBody input").prop("checked", hc);
//     $(this).closest(".MultiCheckBoxDetail").next().UpdateSelect();
//   });

//   $(document).on("click", ".MultiCheckBoxDetailHeader", function (e) {
//     var inp = $(this).find("input");
//     var chk = inp.prop("checked");
//     inp.prop("checked", !chk);
//     $(this).closest(".MultiCheckBoxDetail").find(".MultiCheckBoxDetailBody input").prop("checked", !chk);
//     $(this).closest(".MultiCheckBoxDetail").next().UpdateSelect();
//   });

//   $(document).on("click", ".MultiCheckBoxDetail .cont input", function (e) {
//     e.stopPropagation();
//     $(this).closest(".MultiCheckBoxDetail").next().UpdateSelect();

//     var val = ($(".MultiCheckBoxDetailBody input:checked").length == $(".MultiCheckBoxDetailBody input").length)
//     $(".MultiCheckBoxDetailHeader input").prop("checked", val);
//   });

//   $(document).on("click", ".MultiCheckBoxDetail .cont", function (e) {
//     var inp = $(this).find("input");
//     console.log("--"+inp)
//     var chk = inp.prop("checked");
//     inp.prop("checked", !chk);

//     var multiCheckBoxDetail = $(this).closest(".MultiCheckBoxDetail");
//     var multiCheckBoxDetailBody = $(this).closest(".MultiCheckBoxDetailBody");
//     multiCheckBoxDetail.next().UpdateSelect();

//     var val = ($(".MultiCheckBoxDetailBody input:checked").length == $(".MultiCheckBoxDetailBody input").length)
//     $(".MultiCheckBoxDetailHeader input").prop("checked", val);
//   });

//   $(document).mouseup(function (e) {
//     var container = $(".MultiCheckBoxDetail");
//     if (!container.is(e.target) && container.has(e.target).length === 0) {
//       container.hide();
//     }
//   });
// });

var defaultMultiCheckBoxOption = { width: '220px', defaultText: 'Select Below', height: '200px' };

jQuery.fn.extend({
  CreateMultiCheckBox: function (options) {

    var localOption = {};
    localOption.width = (options != null && options.width != null && options.width != undefined) ? options.width : defaultMultiCheckBoxOption.width;
    localOption.defaultText = (options != null && options.defaultText != null && options.defaultText != undefined) ? options.defaultText : defaultMultiCheckBoxOption.defaultText;
    localOption.height = (options != null && options.height != null && options.height != undefined) ? options.height : defaultMultiCheckBoxOption.height;

    this.hide();
    this.attr("multiple", "multiple");
    var divSel = $("<div class='MultiCheckBox'>" + localOption.defaultText + "<span class='k-icon k-i-arrow-60-down'><svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='sort-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-sort-down fa-w-10 fa-2x'><path fill='currentColor' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z' class=''></path></svg></span></div>").insertBefore(this);
    divSel.css({ "width": localOption.width });
    select_id = this.attr('id')
    var detail = $("<div class='MultiCheckBoxDetail'><div class='MultiCheckBoxDetailHeader'><input type='checkbox' class='"+select_id+" mulinput' value='-1982' /><div class='dropdown-text'>Select All</div></div><div class='MultiCheckBoxDetailBody'></div></div>").insertAfter(divSel);
    detail.css({ "width": parseInt(options.width) + 10, "max-height": localOption.height });
    var multiCheckBoxDetailBody = detail.find(".MultiCheckBoxDetailBody");
    // this.find("option").each(function () {
    //   var val = $(this).attr("value");
    //   if (val == undefined)
    //     val = '';
    //   multiCheckBoxDetailBody.append("<div class='cont'><div><input type='checkbox' class='"+select_id+" mulinput' value='" + val + "' /></div><div class='dropdown-text'>" + $(this).text() + "</div></div>");
    // });

    // multiCheckBoxDetailBody.css("max-height", (parseInt($(".MultiCheckBoxDetail").css("max-height")) - 28) + "px");
  },
  UpdateSelect: function () {
    var arr = [];

    this.prev().find(".mulinput:checked").each(function () {
      arr.push($(this).val());
    });

    this.val(arr);
  },
  UpdateCheck: function(){
    selected_values = []
    selectID = "#"+this.next().attr('id')
    var selected_values = $(selectID+" option:selected").map(function(i, el) {
      return $(el).val();
    }).get();
    console.log("==========="+selected_values)
    this.find('.cont').each(function (ind, ele) {
      var element  = $(ele).find('input.mulinput:checkbox')
      if (selected_values.includes($(element).val())){
        $(element).attr('checked', true)
      }
    })
  },

});
