import {
  Controller
} from "stimulus"
import Rails from '@rails/ujs'
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".handle",
      group: 'shared',
      animation: 150,
      multiDrag: true,
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("sort", event.newIndex + 1)
    let urlset = this.data.get("url").replace(":id", id)
    Rails.ajax({
      url: urlset,
      type: 'PATCH',
      data: data
    })
  }
}
